@use 'src/app/variables' as *;

.underline {
  &,
  &:hover,
  &:focus-visible,
  &:active {
    font-weight: 500;
    text-decoration: underline;
  }
}

.onHover {
  &:hover,
  &:focus-visible,
  &:active {
    text-decoration: underline;
  }
}
